import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetDigitalTwinsPageNumber } from "../../Actions/DigitalTwinsList";
import { SetSessionExpired } from "../../Actions";
import CODE from "../../Static/Constants/StatusCodes";
import { fetchFiltersDataObject } from "../../Helper/Table/TableHelper";
import { getDigitalTwinData } from "../../Api/DigitalTwinListApi";
import AppCache from "../../Helper/Common/AppCache";
import GenericDraggableTableView from "../../Features/Table/GenericDraggableTable";

const COLUMN_SETTING_STORAGE_KEY = "DIGITAL_TWIN_COLUMN_SETTINGS"

export default function InventoryList({
	tabvalue,
	sorting,
	setSorting,
	setOpenBatteryConfigDialog,
	...props
}) {
	const [columns, setColumns] = useState({ 0: [], 1: [] });
	const [filters, setFilters] = useState({})
	const [selectedRowKeys, setSelectedRowKeys] = useState({});

	const dispatch = useDispatch();
	const pageNumber = useSelector((state) => state.DigitalTwinsPageNumber.value);

	const rowsPerPage = useSelector(
		(state) => state.DigitalTwinsRowsPerPage.value
	);

	const total = useSelector((state) => state.DigitalTwinsTotal.value);
	const totalTwins = props.connectedBatteries

	const [list, setList] = useState({
		batteries: {},
		pages: { start: 1, end: 0, total: 0 },
		responseStatus: { code: CODE.LOADING, msg: "" },
	});

	const tabColumns = props.columns[tabvalue]["columns"]

	const [selectedColumns, setSelectedColumns] = useState({ 0: [], 1: [] });
	const [columnOrder, setColumnOrder] = useState({ 0: [], 1: [] });

	const isLoading = list.responseStatus.code === CODE.LOADING

	const setupTabColumns = useCallback(() => {
		if (!columns[tabvalue] || columns[tabvalue].length > 0) return;

		const columnSettingKeys = AppCache.getItem(COLUMN_SETTING_STORAGE_KEY) || {};
		const columnSettingTabKeys = columnSettingKeys[tabvalue] || [];

		const filteredColumns = columnSettingTabKeys.length
			? tabColumns.filter(({ dataIndex }) => columnSettingTabKeys.includes(dataIndex))
			: tabColumns;

		const filteredColumnKeys = columnSettingTabKeys.length
			? columnSettingTabKeys
			: tabColumns.map(({ dataIndex }) => dataIndex);

		setColumns((prev) => ({ ...prev, [tabvalue]: filteredColumns }));
		setSelectedColumns((prev) => ({ ...prev, [tabvalue]: filteredColumnKeys }));

	}, [columns, selectedColumns, tabvalue, tabColumns]);


	const setFiltersAndData = async () => {
		const digitalTwinTableFilters = props.pagesContent?.filterConfigurations?.digitalTwinListTable?.filters
		if (!digitalTwinTableFilters) {
			return false
		}

		const tabFilters = digitalTwinTableFilters.filter((filter, index) => {
			if (!filter.tabs) {
				return true
			}
			return filter.tabs.includes(tabvalue)
		})

		const filtersObj = await fetchFiltersDataObject(tabFilters, { ...filters })

		setFilters(filtersObj)
		setList(props.data);
	}

	const applySort = (key, order) => {
		const tempSort = [];
		tempSort[tabvalue] = { sortBy: key, orderBy: order }
		setSorting(tempSort)
	};

	const onPageChange = (page, pageSize) => {
		dispatch(SetDigitalTwinsPageNumber(page));
	};

	const onSelectChange = (selectedKeys) => {
		const allSelectedRows = { ...selectedRowKeys }
		allSelectedRows[pageNumber] = selectedKeys
		setSelectedRowKeys(allSelectedRows);
	};

	const getTotalRowSelection = () => {
		const combinedArray = Object.values(selectedRowKeys).flat()
		return combinedArray
	}

	const getCurrentPageSelection = () => {
		if (!selectedRowKeys) {
			return []
		}

		if (!selectedRowKeys[pageNumber]) {
			return []
		}

		return selectedRowKeys[pageNumber]
	}

	const getCSVData = async () => {
		const res = await getDigitalTwinData({
			resourceIDs: getTotalRowSelection(),
			actionType: "download-csv",
			resourceType: tabvalue == 0 ? "digital-twin" : "battery-config-list",
			columnOrder: columnOrder[tabvalue],
			columns: JSON.stringify(columns[tabvalue])
		})

		if (res && res.responseStatus.code === 200) {
			return res.response.data
		}

		if (res && res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
		return []
	}

	const onSendEmail = async ({ sendTo, sendCc, subject, body }) => {
		const res = await getDigitalTwinData({
			resourceIDs: getTotalRowSelection(),
			actionType: "mail",
			resourceType: tabvalue == 0 ? "digital-twin" : "battery-config-list",
			columnOrder: columnOrder[tabvalue],
			columns: JSON.stringify(columns[tabvalue]),
			emailTo: sendTo,
			emailCc: sendCc,
			subject: subject,
			body: body
		})

		if (res && res.responseStatus.code === 200) {
			return res.response.data
		}

		if (res && res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
		return []
	}

	const applySelectedColumns = (newSelectedColumns) => {

		const filteredColumns = tabColumns.filter((columnConfig) => {
			return newSelectedColumns.includes(columnConfig.dataIndex)
		})

		setColumns((prevColumns) => {
			const updatedColumns = { ...prevColumns }
			updatedColumns[tabvalue] = filteredColumns
			console.log("setColumns", updatedColumns)
			return updatedColumns;
		})

		setSelectedColumns((prevSelectedColumns) => {
			const updatedSelectedColumns = { ...prevSelectedColumns }
			updatedSelectedColumns[tabvalue] = newSelectedColumns
			AppCache.setItem(COLUMN_SETTING_STORAGE_KEY, updatedSelectedColumns)
			return updatedSelectedColumns;
		})
	}

	const handlePrimaryActionButtonClick = () => {
		if (tabvalue == 0) {
			return props.navigate("/DigitalTwin/add-battery")
		}

		return setOpenBatteryConfigDialog(true)

	}

	const shouldShowAddResourcesButton = () => {
		if (tabvalue == 0) {
			return props?.permissions?.includes("add-twin")
		}

		return props?.permissions?.includes("add-config")
	}

	const onColumnOrderChange = (tableColumns) => {
		const updatedColumnOrder = { ...columnOrder }
		updatedColumnOrder[tabvalue] = tableColumns.map((value) => value.headerLabel)
		setColumnOrder(updatedColumnOrder);
	}

	useEffect(() => {
		setFiltersAndData()
	}, [props.data]);

	useEffect(() => {
		setupTabColumns()
	}, [tabColumns]);

	useEffect(() => {
		setSelectedRowKeys({})
		setFilters({})
	}, [tabvalue]);


	return (
		<GenericDraggableTableView
			title={tabvalue == 0 ? "All Digital Twins" : "All Battery Configuration"}
			count={tabvalue == 0 ? totalTwins : list?.pages?.total}
			primaryButtonLabel={tabvalue == 0 ? "+ Add Twin" : "+ Add Configuration"}
			showPrimaryButton={shouldShowAddResourcesButton()}
			onPrimaryButtonClick={() => handlePrimaryActionButtonClick()}
			applySearch={(selectedDevices) => props.setSearchTerm(selectedDevices)}
			disableSearch={tabvalue != 0}
			getTotalRowSelection={getTotalRowSelection}
			getCSVData={getCSVData}
			onSendEmail={onSendEmail}
			availableEmailDomains={props.pagesContent.availableEmailDomains || []}
			filters={filters}
			selectedFilters={props.selectedFilters}
			applyFilters={(args) => props.setSelectedFilters(args)}
			initialColumns={tabColumns}
			selectedColumns={selectedColumns[tabvalue]}
			applySelectedColumns={applySelectedColumns}
			filteredCount={Object.keys(props.selectedFilters).length > 0 ? list?.pages?.total : 0}
			tableColumns={columns[tabvalue]}
			tableData={list.responseStatus.code === CODE.SUCCESS ? list.batteries : []}
			onColumnOrderChange={onColumnOrderChange}
			columnOrder={columnOrder[tabvalue]}
			isLoading={isLoading}
			pageNumber={pageNumber}
			rowsPerPage={rowsPerPage}
			total={total}
			applySort={applySort}
			onPageChange={onPageChange}
			emptyMessage="No matches found for filters applied"
			setSelectedRowKeys={setSelectedRowKeys}
			rowSelection={{
				selectedRowKeys: getCurrentPageSelection(),
				onChange: onSelectChange,
			}}
		/>
	)

}
