import React, { useState } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "universal-cookie";
import { OutlinedTextField } from "../Components/CustomTextField";
import { getOrgInfo, getUserPrefrenceTimezone } from "../Api/Api";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CoulombLogo from "../Assets/Icons/CoulombLogo.svg";

import "./Login.css";
import { ThemeProperties } from "../Theme/ThemeProperties";
import { getLocationFilter, getOrgFilter } from "../Api/MapApi";
import { SetMapCity, SetOrgFilter } from "../Actions/Map";
import { SetDiagnosticsCity } from "../Actions/Diagnostics";
import FindLocalTimezone from "../Helper/Operations/FindLocalTimezone";
import AppCache from "../Helper/Common/AppCache";

function Copyright(props) {
  return (
    <Typography align="center" {...props} sx={{ fontSize: "12px", mt: 2 }}>
      {"Copyright © "}
      <Link color="inherit" href="https://coulomb.ai/">
        Coulomb AI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login(props) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const isPassReset = useSelector((state) => state.PassChangedSuccess.value);

  const formatInputUserName = (event) => {
    setUsername(event.target.value.trim());
  };

  const formatInputPassword = (event) => {
    setPassword(event.target.value.trim());
  };

  const [errorText, setErrorText] = React.useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    var credentials = JSON.stringify({
      username: data.get("email").trim(),
      password: data.get("password").trim(),
    });

    var config = {
      method: "post",
      url: "/api/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: credentials,
    };

    const userPermissions = ["view-twin", "view-config"];
    const adminPermissions = [
      "add-twin",
      "edit-twin",
      "view-twin",
      "add-config",
      "edit-config",
      "view-config",
    ];

    axios(config)
      .then((res) => {
        if (res.data.responseStatus.code === 200) {
          cookies.remove("finalCall", { path: "/" });
          cookies.set("name", res.data.loginData.name, { path: "/" });
          cookies.set("fleetID", res.data.loginData.fleetID, { path: "/" });
          const role = cookies.get("role");
          cookies.set("permissions", role === "admin" ? adminPermissions : userPermissions)
          
          setErrorText("");
          const myPages = [
            {
              to: "/DigitalTwin",
              text: "Digital Twin List",
              mykey: "DigitalTwin",
              iconName: "navbarDigitalTwin",
              isActive: true,
              queryParam: {},
              permissions:
                role === "admin" ? adminPermissions : userPermissions,
            },
            {
              to: "/inventory",
              text: "Inventory page",
              mykey: "Inventory",
              iconName: "navbarDigitalTwin",
              isActive: true,
              queryParam: {},
              permissions:
                role === "admin" ? adminPermissions : userPermissions,
            },
            {
              to: "/Map",
              iconName: "navbarMap",
              text: "Map",
              mykey: "Map",
              isActive: true,
              queryParam: {},
            },
            {
              to: "/Alerts",
              text: "Alerts",
              mykey: "Alerts",
              iconName: "navbarAlerts",
              isActive: true,
              queryParam: {},
            },
            {
              to: "/BatteryHealth",
              text: "Battery Health",
              mykey: "BatteryHealth",
              isActive: true,
              queryParam: {},
              iconName: "navbarBatteryHealth",
            },
            {
              to: "/Diagnostics",
              text: "Diagnostics",
              mykey: "Diagnostics",
              isActive: true,
              queryParam: {},
              iconName: "navbarDiagnostics",
            },
            {
              listItems: [
                {
                  to: "/ChargingAnalytics",
                  text: "Charging Analytics",
                  mykey: "ChargingAnalytics",
                  isActive: true,
                  queryParam: {},
                },
                {
                  to: "/PerformanceAnalytics",
                  text: "Performance",
                  mykey: "PerformanceAnalytics",
                  isActive: false,
                  queryParam: {},
                },
                {
                  to: "/AlertAnalytics",
                  text: "Alert Analytics",
                  mykey: "AlertAnalytics",
                  isActive: true,
                  queryParam: {},
                },
                {
                  to: "/DataQuality",
                  text: "Data Quality",
                  mykey: "DataQuality",
                  isActive: false,
                  queryParam: {},
                },
                {
                  to: "/DriverBehavior",
                  text: "Driver Behavior",
                  mykey: "DriverBehavior",
                  isActive: false,
                  queryParam: {},
                },
              ],
              title: "Analytics",
              iconName: "analytics"
            },
          ];

          const newPagesMeta = [];
          myPages?.forEach((item) => {
            if (item?.listItems) {
              item.listItems?.map((page) => newPagesMeta.push(page))
            } else {
              newPagesMeta.push(item)
            }
          });
          cookies.set("pagesMeta", newPagesMeta);
          cookies.set("navigationCard", myPages);
          AppCache.clearOnLogin()
        } else {
          setErrorText("Invalid Login Id or password");
        }
        return res.data.responseStatus.code;
      })
      .then(() => {
        getUserPrefrenceTimezone().then((res) => {
          if (res.responseStatus.code === 200) {
            if (res.response.response.preferenceData === null) {
              cookies.set("timeZone", res.response.response.primaryTimeZone, {
                path: "/",
              });
              cookies.set("isAutomaticTimezone", 0, {
                path: "/",
              });
            } else if (res.response.response.preferenceData !== null) {
              if (res.response.response.preferenceData.isAutomaticTime === 1) {
                let findLocalTimeZone = FindLocalTimezone();
                if (findLocalTimeZone !== undefined) {
                  cookies.set("timeZone", findLocalTimeZone["name"], {
                    path: "/",
                  });
                  cookies.set("isAutomaticTimezone", 1, {
                    path: "/",
                  });
                }
              } else if (
                res.response.response.preferenceData.timezonename !== null
              ) {
                cookies.set(
                  "timeZone",
                  res.response.response.preferenceData.timezonename,
                  {
                    path: "/",
                  }
                );
                cookies.set("isAutomaticTimezone", 0, {
                  path: "/",
                });
              }
            }
          }
        });

        getOrgInfo().then((res) => {
          if (res.responseStatus.code === 200) {
            cookies.set("orgName", res.response.orgName, { path: "/" });
            cookies.set("sessionId", new Date().getTime() / 1000, {
              path: "/",
            });
            cookies.set("amplitudeID", res.response.amplitudeID, {
              path: "/",
            });
            cookies.set("pages", res.response.metaInfo?.pagesToShow || [
              "Map",
              "Landing",
              "Alerts",
              "SpecificBattery",
              "DigitalTwin",
              "Diagnostics",
              "BatteryHealth",
              "chargingAnalytics",
              "alertAnalytics",
              "alertDrillDown"
            ]);
            props.setLoggedIn(!props.LoggedIn);
          }
        });

        getOrgFilter().then((res) => {
          if (res.responseStatus.code === 200) {
            let fleetName = res.response.filterData.find(
              (i) => i.type === "fleetName"
            );
            if (fleetName !== undefined) {
              cookies.set("orgFilter", fleetName.value, {
                path: "/",
                maxAge: 86400,
              });
              localStorage.setItem("OrgFilter", fleetName.value);
              dispatch(SetOrgFilter(fleetName.value));
            }
          }
        });

        getLocationFilter().then((res) => {
          if (res.responseStatus.code === 200) {
            cookies.set("locationFilter", res.response.locationFilterData, {
              path: "/",
              maxAge: 1800,
            });
            localStorage.setItem(
              "LocationFilter",
              res.response.locationFilterData
            );
            dispatch(SetMapCity(res.response.locationFilterData));
            dispatch(SetDiagnosticsCity(res.response.locationFilterData));
          }
        });
      })

      .catch(function (error) { });
  };

  return (
    <Box
      sx={{
        display: "table",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        bgcolor: "#F2F2F4",
      }}
    >
      <Box
        sx={{
          display: "table-cell",
          verticalAlign: "middle",
        }}
      >
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "500px",
            mb: 2,
            //   padding: "0px 56px ",
          }}
        >
          {isPassReset && (
            <div className={`passChangedSuccess`}>
              <Typography variant="textPasswordReset" align="center">
                Password is changed successfully!
              </Typography>
            </div>
          )}
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "center", mb: 2, ml: -0.8 }}
        >
          <img src={CoulombLogo} alt="not found" height={"40px"} />
        </Box>

        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "450px",
            border: "1px solid #DDD7FF",
            borderRadius: "1.2rem",
            backgroundColor: "#fff",
            padding: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="paperHeader"
              sx={{
                fontSize: "20px",
              }}
            >
              Login to Battery Observability Platform
            </Typography>
            <br></br>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <OutlinedTextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Login ID"
                value={username}
                onChange={(e) => formatInputUserName(e)}
                name="email"
                autoComplete="email"
                autoFocus
                error={errorText.length === 0 ? false : true}
              />
              <OutlinedTextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                value={password || null}
                onChange={(e) => formatInputPassword(e)}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="new-password"
                error={errorText.length === 0 ? false : true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box sx={{ m: "5px 0px 10px" }}>
                <Typography variant="textSubtitleRed">{errorText}</Typography>
              </Box>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography
                    variant="textSubtitle"
                    onClick={() => navigate("/reset_password")}
                    sx={{ cursor: "pointer", color: ThemeProperties.purple }}
                  >
                    Reset Password
                  </Typography>
                </div>

                <div>
                  <Typography
                    variant="textSubtitle"
                    onClick={() => navigate("/forgot_password")}
                    sx={{ cursor: "pointer", color: ThemeProperties.purple }}
                  >
                    Forgot Password?
                  </Typography>
                </div>
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, fontSize: 13, borderRadius: "1rem" }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Box>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Box>
    </Box>
  );
}
