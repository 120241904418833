import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Box, Typography } from "@mui/material";
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

const getColumnTitleSort = (titleProps, key, title, subTitle = "") => {
  const sortedColumn = titleProps.sortColumns?.find(
    ({ column }) => column.key === key
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography
          sx = {{px: 1}}
          variant="fw500s14lh24c2E2E2E"
          onClick={(e) => e.stopPropagation()}
        >
          {title}
        </Typography>
        {subTitle && 
          <Typography
            style={{ 
              fontSize: "11px",
              color: "#828A92"
            }}
            onClick={(e) => e.stopPropagation()}
          >
          {subTitle}
        </Typography>}
      </Box>

      <div className="sortIconDiv">
        <div style={{ margin: "0px 3px" }}>
          <CaretUpOutlined
            className={
              sortedColumn?.order === "ascend" &&
              sortedColumn?.column.key === key
                ? "sortIcon up active"
                : "sortIcon up"
            }
          />
        </div>
        <div>
          <CaretDownOutlined
            className={
              sortedColumn?.order === "descend" &&
              sortedColumn?.column.key === key
                ? "sortIcon down active"
                : "sortIcon down"
            }
          />
        </div>
      </div>
    </div>
  );
};

const handleScroll = (arr) => {
  let x = 0;
  let elewidth = 0;
  let widthCount = 0;
  arr.forEach((ele) => {
    if (ele.hidden === false) {
      widthCount++;
      if (ele.width) {
        elewidth += ele.width;
        widthCount--;
      }
    }
  });
  let width = widthCount * 240 + elewidth;

  width > 600 ? (x = width) : (x = 0);

  return {
    x: x,
    y: 500,
  };
};


export { getColumnTitleSort, handleScroll };
