import { TableColumnValueRenderer } from "./Renderers/TableColumnValueRenderer";
import TableHeader from "./Renderers/TableHeader";

export const getColumnsConfig = ({
    tableColumns,
    tableColumnOrder,
    shouldShowActionMenu = () => true,
    customRenderers
  }) => {
    const columnOrdering = tableColumnOrder || [];
  
    return columnOrdering
      .map((columnKey, index) => {
        const config = tableColumns?.[columnKey];
        if (!config) return null; 
  
        if (config?.valueRenderingType === "action-menu" && !shouldShowActionMenu(config)) {
          return null;
        }

        const key = config?.key || columnKey
  
        return {
          ...config,
          dataIndex: config?.dataIndex,
          headerLabel: config.title,
          key: key,
          sorter: config?.sorter || false,
          defaultSortOrder: config?.defaultSortOrder || "",
          showSorterTooltip: config?.showSorterTooltip || false,
          sortDirections: config?.sortDirections,
          filterMode: config?.filterMode || "tree",
          filterSearch: config?.filterSearch || false,
          decimalPlaces: config?.decimalPlaces || 0,
          uniqueKey: `${columnKey}UniqueKey${index}`,
          align: config?.align || "left",
          width: config?.width || "auto",
          show: config?.tabs,
          fixed: config?.fixed || undefined,
          title: (titleProps) => (
            <TableHeader
              key = {key}
              titleProps={titleProps}
              config={config}
            />
          ),
          render: (value, record) => (
            <TableColumnValueRenderer
              key = {key}
              record={record}
              config={config}
              customRenderers={customRenderers}
            />
          ),
        };
      })
      .filter(Boolean); // ** Removes null values**
  };