import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";
import { ThemeProperties } from "./ThemeProperties";

const theme = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: ThemeProperties.purple,
      dark: ThemeProperties.purple,
      light: ThemeProperties.purpleLight,
      textHighlight: ThemeProperties.darkerGrey,
      textMuted: ThemeProperties.lightGrey
    },
    error: {
      main: ThemeProperties.red_400,
    },
    customRibRed: {
      main: red[400],
      superDark: red[800],
      superLight: red[100],
    },
  },
  formLabel: {
    color: ThemeProperties.black,
  },
  textField: {
    fontSize: 14,
    padding: 7,
    marginLeft: 3,
  },
  typography: {

    fw600s14lh20c3D4B5A: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#3D4B5A'
    },

    fw400s14lh19c000000: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: '#000000 !important'
    },

    fw600s15lh17c000000: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '17px',
      color: '#000000 !important'
    },

    fw500s12lh14c3D4B5A: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#3D4B5A !important'
    },

    fw500s14lh16cFFFFFF: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#FFFFFF !important'
    },

    fw600s14lh16c6D72F6: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#6D72F6 !important'
    },

    fw500s14lh24c2E2E2E: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#2E2E2E !important'
    },

    fw400s14lh24c3D4B5A: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#3D4B5A !important'
    },

    fw400s14lh24c0061CA: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#0061CA !important'
    },

    fw400s12lh14c4B4B4B: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#4B4B4B !important'
    },

    fw400s14lh16c4B4B4B: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#4B4B4B !important'
    },

    fw400s12lh24c989898: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      color: '#989898 !important'
    },

   fw500s10lh24c5E758D: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '24px',
      color: '#5E758D'
   },

    fw500s12lh24c4D44D8 : {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '24px',
      color: '#4D44D8'
    },

    fw500s12lh24c474747 : {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '24px',
      color: '#474747'
    },

    fw500s12lh24c808080 : {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '24px',
      color: '#808080'
    },

    fw400s12lh24c6D72F6 : {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      color: '#6D72F6'
    },

    fw500s12lh246D72F6: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '24px',
      color: '#6D72F6'
    },

    fw600s10lh24c6D72F6: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: '24px',
      color: '#6D72F6'
    },

    fw400s12lh24c3D4B5A: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '24px',
      color: '#3D4B5A'
    },

    fw500s12lh24c6D72F6: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '24px',
      color: '#6D72F6'
    },

    fw700s12lh24c3D4B5A: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '24px',
      color: '#3D4B5A'
    },

    fw700s14lh24c3D4B5A : {
      fontFamily: ThemeProperties.primaryFontV2,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '24px',
      color: '#3D4B5A'
    },

    fw600s12lh24c6D72F6: {
      fontFamily: ThemeProperties.primaryFontV2,
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '24px',
      color: '#6D72F6'
    },

    fw500s16lh24c000000:{
      fontFamily: ThemeProperties.primaryFontV2,
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      color: '#000000'
    },

    tableRow: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "13.5px",
      "@media (max-width:1350px)": {
        fontSize: "12.5px",
      },
      color: ThemeProperties.black,
    },
    tableHeadBold: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "14px",
      fontWeight: "bold",
    },
    specificTabBold: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "16px",
      fontWeight: "bold",
    },
    tableHead: {
      fontWeight: 600,
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      "@media (max-width:1350px)": {
        fontSize: "1.2rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.1rem",
      },
      color: ThemeProperties.mainTextColor,
    },
    paperHeader: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: 15,
      fontWeight: 600,
      color: ThemeProperties.mainTextColor,
    },
    paperHeaderWhite: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: 15,
      fontWeight: 400,
      color: ThemeProperties.white,
    },

    bigNumberPurple: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "4.8rem",
      fontWeight: 300,
      color: ThemeProperties.purple,
    },

    mediumNumberPurple: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "2.4rem",
      fontWeight: 400,
      color: ThemeProperties.purple,
    },

    mediumNumberBlack: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "2.4rem",
      fontWeight: 400,
      color: ThemeProperties.black,
    },

    textDownloadCSV: {
      fontSize: 11,
      fontWeight: 500,
      fontFamily: "Roboto",
    },

    textSubtitle: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      "@media (max-width:1350px)": {
        fontSize: "1.3rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.2rem",
      },
      fontWeight: 400,
      color: ThemeProperties.mainTextColor,
    },

    textPasswordReset: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      "@media (max-width:1350px)": {
        fontSize: "1.3rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.2rem",
      },
      fontWeight: 300,
      color: "#444",
      p: "1rem",
      margin: "1rem",
    },

    textDisabled: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 400,
      color: "rgba(0,0,0,0.40)",
    },

    textBoldSubtitle: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      "@media (max-width:1350px)": {
        fontSize: "1.3rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1.2rem",
      },
      fontWeight: 500,
      color: ThemeProperties.black,
    },

    textBoldSubtitleMd: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.2rem",
      fontWeight: 500,
      color: ThemeProperties.mainTextColor,
    },
    textBoldSubtitleLg: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 500,
      color: ThemeProperties.mainTextColor,
    },
    textBoldSubtitleXl: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 500,
      color: ThemeProperties.mainTextColor,
    },
    textSubtitlePurple: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 400,
      color: ThemeProperties.purple,
    },
    textSubtitleWhite: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 400,
      color: ThemeProperties.white,
    },
    textSubtitleRed: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 400,
      color: ThemeProperties.red_400,
    },
    textSubtitleGrey: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.4rem",
      fontWeight: 400,
      color: "#a9a9a9",
    },
    textSubtitleDarkGrey: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.2rem",
      fontWeight: 400,
      color: ThemeProperties.greyTextColor,
    },
    textSubtitleSmall: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.3rem",
      fontWeight: 400,
      color: ThemeProperties.mainTextColor,
    },
    textSubtitleBold: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: 20,
      fontWeight: 600,
      color: ThemeProperties.mainTextColor,
    },
    textSubtitleBoldRed: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.6rem",
      fontWeight: 500,
      color: ThemeProperties.red_400,
    },
    textSubtitleBoldPurple: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.6rem",
      fontWeight: 500,
      color: ThemeProperties.purple,
    },
    textDescription: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.2rem",
      "@media (max-width:1350px)": {
        fontSize: "1.1rem",
      },
      "@media (max-width:900px)": {
        fontSize: "1rem",
      },
      fontWeight: 500,
      color: ThemeProperties.greyTextColor,
    },
    textDescriptionActive: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.3rem",
      fontWeight: 500,
      color: ThemeProperties.black,
    },
    textDescriptionActivePurple: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.2rem",
      fontWeight: 500,
      color: ThemeProperties.purple,
    },
    textDescriptionActiveWhite: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.2rem",
      fontWeight: 500,
      color: ThemeProperties.white,
    },
    textDescriptionActiveBlack: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.2rem",
      fontWeight: 500,
      color: ThemeProperties.black,
    },
    mapLegend: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.3rem",
      fontWeight: 500,
      lineHeight: "2rem",
      color: ThemeProperties.black,
    },
    textTimezone: {
      fontFamily: ThemeProperties.primaryFont,
      fontSize: "1.3rem",
      fontWeight: 400,
      lineHeight: "2rem",
      color: "#666",
    },
    pageHeading: {
      fontFamily: "Roboto",
      fontSize: "2.2rem",
      fontStyle: "normal",
      fontWeight: 500,
      color: ThemeProperties.black,
      lineHeight: "28px",
    },
  },
  
});

export default theme;
