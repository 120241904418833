import {  Typography, Box, IconButton } from "@mui/material";

import SortUpIcon from '@mui/icons-material/ArrowDropUp';
import SortDownIcon from '@mui/icons-material/ArrowDropDown';

const TableHeader = ({ titleProps, config }) => {
  const title = config.title;
  const key = config?.dataIndex;
  const sorter = config?.sorter;

  const sortedColumn = titleProps?.sortColumns?.find(({ column }) => column.key === key);
  const isAscActive = sortedColumn?.order === "ascend";
  const isDescActive = sortedColumn?.order === "descend";

  return (
    <Box display="flex" alignItems="center" sx = {{  marginLeft: '8px'}}>
      <Typography onClick = {(e) =>  e.stopPropagation() } variant="fw500s14lh24c2E2E2E">{title}</Typography>

       {sorter && (
        <Box display="flex" flexDirection="column" alignItems="center" sx= {{marginLeft: '12px'}}>
          <IconButton size="small" sx = {{ width: 10, height: 10, p: 0 }}>
            <SortUpIcon sx = {{  fontSize: "20px", color: isAscActive ? "#4D44D8" : "#BBBBBB"}} />
          </IconButton>

          <IconButton size="small" sx = {{ width: 10, height: 10, p: 0 }}>
            <SortDownIcon sx = {{ fontSize: "20px", color: isDescActive ? "#4D44D8" : "#BBBBBB"}} />
          </IconButton>
        </Box>
      )}
    
    </Box>
  );
};

export default TableHeader;
