import { SvgIcon } from "@mui/material";
import React from "react";


export const FilterIconSvg = (props) => (
    <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.69575 5.91375L13.9111 0.538396C14.006 0.441108 14.0265 0.305103 13.965 0.18948C13.9029 0.0738542 13.7699 0 13.6235 0H0.376485C0.230051 0 0.0970863 0.0738581 0.0350077 0.18948C-0.0264951 0.305106 -0.00599414 0.441107 0.0888961 0.538396L5.30425 5.91375C5.36341 5.97284 5.39504 6.04976 5.39269 6.1287V11.832C5.39094 11.8962 5.43252 11.9553 5.49871 11.9833C5.56548 12.0118 5.64456 12.0032 5.70138 11.9624L8.60723 9.88675V6.1287C8.60489 6.04975 8.63659 5.97284 8.69575 5.91375Z"/>
    </SvgIcon>
);

export const ColumnIconSvg = (props) => (
  <SvgIcon width="14" height="12" viewBox="0 0 14 12" {...props}>
    <path d="M3.15755 0.000553449C3.80189 0.000553449 4.32422 0.522887 4.32422 1.16722V10.8339C4.32422 11.4782 3.80189 12.0006 3.15755 12.0006H1.49089C0.846552 12.0006 0.324219 11.4782 0.324219 10.8339V1.16722C0.324219 0.555107 0.795625 0.0530935 1.3952 0.00442012L1.49089 0.000553449H3.15755ZM12.5083 0.000553449C13.1526 0.000553449 13.6749 0.522887 13.6749 1.16722V10.8339C13.6749 11.4782 13.1526 12.0006 12.5083 12.0006H10.8416C10.1973 12.0006 9.67493 11.4782 9.67493 10.8339V1.16722C9.67493 0.522887 10.1973 0.000553449 10.8416 0.000553449H12.5083ZM7.82419 0C8.46852 0 8.99086 0.522333 8.99086 1.16667V10.8334C8.99086 11.4777 8.46852 12 7.82419 12H6.15753C5.51322 12 4.99089 11.4777 4.99089 10.8334V1.16667C4.99089 0.522333 5.51322 0 6.15753 0H7.82419Z"/>
  </SvgIcon>
)

export const SearchIcon = (props) => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
      <rect width="16.875" height="16.875" transform="translate(0.5 0.5625)" fill="white" fill-opacity="0.01"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8268 11.8762L15.7818 14.8837C15.9142 15.0268 15.9857 15.2158 15.9811 15.4107C15.9765 15.6056 15.8962 15.791 15.7573 15.9277C15.6183 16.0644 15.4316 16.1417 15.2367 16.1431C15.0418 16.1445 14.854 16.0699 14.7131 15.9352L11.7596 12.9307C10.62 13.7892 9.19673 14.1826 7.77802 14.0312C6.35931 13.8797 5.05116 13.1949 4.11843 12.1152C3.1857 11.0355 2.69811 9.64174 2.75438 8.21608C2.81065 6.79043 3.40658 5.43942 4.4215 4.43662C5.43642 3.43383 6.79449 2.85418 8.22072 2.81505C9.64695 2.77591 11.0348 3.28022 12.1031 4.22585C13.1715 5.17149 13.8406 6.48778 13.975 7.90821C14.1094 9.32864 13.6989 10.747 12.8268 11.8762ZM8.37482 12.5625C9.46883 12.5625 10.518 12.1279 11.2916 11.3543C12.0652 10.5807 12.4998 9.53151 12.4998 8.43749C12.4998 7.34347 12.0652 6.29426 11.2916 5.52068C10.518 4.74709 9.46883 4.31249 8.37482 4.31249C7.2808 4.31249 6.23159 4.74709 5.458 5.52068C4.68441 6.29426 4.24982 7.34347 4.24982 8.43749C4.24982 9.53151 4.68441 10.5807 5.458 11.3543C6.23159 12.1279 7.2808 12.5625 8.37482 12.5625Z" />
  </SvgIcon>
)