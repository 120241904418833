import React from "react";
import { Typography } from "@mui/material";
import CustomIcon from "../../../../Components/CustomIcon";
import AlertConfigurationMap from "./columnTypes/AlertConfigurationMap";
import TextWithCopyIcon from "../../../../Helper/Operations/TextWithCopyIcon";
import ProgressBarColumnView from "./columnTypes/ProgressBarColumnView";
import ActionMenu from "./columnTypes/ActionMenu";
import Timestamp from "./columnTypes/Timestamp";


export const  TableColumnValueRenderer = ({
    record, 
    config, 
    customRenderers = {}
  }) => {
  
    const value = record?.[config?.dataIndex];
    const decimalPlaces = config?.decimalPlaces
    const type = config?.valueRenderingType
    const copyToClipBoardTypeName = config?.copyToClipBoardTypeName


    if (customRenderers[type]) {
        return customRenderers[type](record, config);
    }

    switch(type) {
        case 'ping-status-icon':
            return <CustomIcon name={value === "Active" ? "online" : "offline"} style={{ width: "24px" }} />;
        case 'text':
            return <Typography variant="fw400s14lh24c3D4B5A">{value}</Typography>;
        case 'text-with-copy':
            return <div className="renderID">{TextWithCopyIcon(value, copyToClipBoardTypeName)}</div>;
        case 'number':
            return <Typography sx = {{ 
                textAlign: 'right', 
                display: 'flex', 
                justifyContent: 'flex-end'
            }} variant="fw400s14lh24c3D4B5A">{value?.toFixed(decimalPlaces)}</Typography>;
        case 'timestamp':
            return <Timestamp config = {config} record = {record} />

        case 'digital-twin-list-series-parallel':
            const level = config?.seriesParallelConfigLevel
            const tableValue = level === "pack" 
                            ? `${record?.seriesPacks}S / ${record?.parallelPacks}P` 
                            : level === "module"
                              ? `${record?.seriesModulesInPack}S / ${record?.parallelModulesInPack}P`
                              : `${record?.seriesModulesInPack * record?.seriesCellsInModule}S / ${record?.parallelModulesInPack * record?.parallelCellsInModule}P`

            return <Typography variant="tableRow">{tableValue}</Typography>;
        case 'digital-twin-list-past-current':
            return <Typography variant="fw400s14lh24c3D4B5A">{record?.minCurrentPack !== null && record?.maxCurrentPack !== null ? `${record?.minCurrentPack} min / ${record?.maxCurrentPack} max` : ""}</Typography>;
        case 'digital-twin-list-past-voltage':
            return <Typography variant="fw400s14lh24c3D4B5A">{record?.minVoltageCell !== null && record?.maxVoltageCell !== null ? `${record?.minVoltageCell} min / ${record?.maxVoltageCell} max` : ""}</Typography>;
        case 'digital-twin-list-past-temperature':
            return <Typography variant="fw400s14lh24c3D4B5A">{record?.minTemperaturePack !== null && record?.maxTemperaturePack !== null ? `${record?.minTemperaturePack} min / ${record?.maxTemperaturePack} max` : ""}</Typography>;
        case 'alert-configuration-map':
          return <AlertConfigurationMap config = {record} />

        case 'action-menu':
        let actions = config.actions ? config.actions : []
            return <ActionMenu record = {record} config = {config} actions = {actions} >Menu</ActionMenu>

        case 'age':
        let age = value
        let ageUnit  = config?.ageUnit ? config?.ageUnit : 'days'
        let requiredUnit = config?.requiredAgeUnit ? config?.requiredAgeUnit : 'months'
        if(!age) {
          return <Typography variant="fw400s14lh24c3D4B5A">Not Available</Typography>
        }
        age = (age / 30).toFixed(0)
        
        return <Typography sx = {{textAlign: 'right', display: 'flex', justifyContent: 'flex-end'}} variant="fw400s14lh24c3D4B5A">{age}</Typography>;

        case 'progress-bar':
          const barColor = '#000'
          const startProgressFrom = config?.startProgressFrom ? config?.startProgressFrom : 0
          return <ProgressBarColumnView minProgress={startProgressFrom} defaultText = {"Not Available"} value = {value ? value?.toFixed(decimalPlaces) : 0}  barColor= {barColor} record = {record} config = {config} />
        default:
            
        return null;
    }
}
