import React from 'react';
import Grid2 from '@mui/material/Grid2';
import { Box, Typography } from '@mui/material';

import TableColumns from './TableFilters/TableColumns';
import TableFilters from './TableFilters/TableFilters';

const TableFiltersAndColumnSettings = ({
  initialColumns = [],
  filters = {},
  selectedFilters = {},
  selectedColumns = [],
  setSelectedFilters = () => {},
  setSelectedColumns = () => {},
  filteredCount = 0
}) => {
  
  return (
    <Grid2 
        container
        alignItems="center" 
        direction="row" 
        justifyContent = "space-between"
        spacing={1}
        sx={{ 
        mb: 2, 
        bgcolor: '#F8F8F8', 
        borderRadius: '10px', 
        border: '1px solid #F3F3F3', 
        borderTop: '1px solid #F3F3F3', 
        p: 1 
    }}> 
        <Grid2 container direction="row" spacing={1}  >
            <TableColumns
                initialColumns = {initialColumns} 
                selectedColumns = {selectedColumns} 
                setSelectedColumns = {setSelectedColumns} />

            <TableFilters 
                filters = {filters} 
                selectedFilters = {selectedFilters} 
                setSelectedFilters = {setSelectedFilters} />
        </Grid2>

        <Box>
            {
                
            filteredCount > 0 && <Typography variant="fw500s12lh14c3D4B5A"  sx={{ mx: 1}}> {filteredCount} Results</Typography>
            }
        </Box>


    </Grid2>
  );
};


export default TableFiltersAndColumnSettings;
