import React, { useState, useCallback } from "react";
import { Box, IconButton, Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeviceSearchInput from "../../../Components/DeviceSearchInput";
import { PrimaryTableChip } from "../GenericDraggableTable/TableStyles";
import {SearchIcon} from '../Icons/index'

export default function TableSearch({ onSearch = () => {} }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedBatteries, setSelectedBatteries] = useState([]);
  const visibleBatteries = selectedBatteries.slice(-3);
  const remainingCount = selectedBatteries.length - visibleBatteries.length;

  const haveSearchValues = selectedBatteries && selectedBatteries.length > 0


  const handleClearSelection = () => {
    if (haveSearchValues) {
      setSelectedBatteries([]);
      onSearch([]);
    }
  
  };

  const closeSearchBox = () => {
    if(haveSearchValues) {
      return true
    }

    handleClearSelection()
    setIsExpanded(false);
  }

  const handleSelectBattery = useCallback(
    (selectedValue) => {
      setSelectedBatteries((prev) => {
        if (prev.includes(selectedValue)) {
          return prev;
        }
        return [...prev, selectedValue];
      });
    },
    [setSelectedBatteries]
  );

  const handleSearchButton = () => {
    if (!haveSearchValues) {
      return false;
    }
    onSearch(selectedBatteries);
  };

  const handleDeleteBattery = (batteryToDelete) => () => {
    if (selectedBatteries && selectedBatteries.length  == 1) {
      handleClearSelection()
      return true
    }

    setSelectedBatteries((prev) => {
        const updatedSelectedBatteries = prev.filter((battery) => battery !== batteryToDelete)
        onSearch(updatedSelectedBatteries)
        return updatedSelectedBatteries
      }
    );
  };

  if (!isExpanded) {
    return (
      <IconButton
        onClick={() => setIsExpanded(true)}
        size="small"
        sx={{
          width: 30,
          height: 30,
          borderRadius: "50px",
          border: "1px solid #E9ECEF",
          "&:hover": {
            border: "1px solid #768CFF",
            "& .MuiSvgIcon-root": {
              color: "#8489FF",
            },
          },
        }}
      >
        <SearchIcon sx={{ fontSize: "20px", color: "#5E758D" }} />
      </IconButton>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        border: "1px solid #EEEFFF",
        borderRadius: "20px",
        padding: "0px 8px",
        width: "710px",
        height: '32px'
      }}
    >

      <IconButton onClick={closeSearchBox} size="small" sx = {{ marginRight: '0px', p:0,  cursor:  haveSearchValues ? "auto" : "pointer"}}>
        <SearchIcon sx={{ fontSize: "18px",  color: "#808080"}} />
      </IconButton>

      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        sx={{ gap: "4px", flexShrink: 0 }}
      >
        {visibleBatteries.map((device, index) => (
          <PrimaryTableChip
            key={index}
            label={device}
            onDelete={handleDeleteBattery(device)}
            deleteIcon={<CloseRoundedIcon />}
          />
        ))}

        {remainingCount > 0 && (
          <Chip
            key={"remaining"}
            label={`+${remainingCount}`}
            sx={{
              background: "#F4F5FF",
              border: "1px solid #F4F5FF",
              borderRadius: "18px",
              px: 0.5,
              maxHeight: '22px',
              fontSize: "12px",
              color: "#6D72F6",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        )}
      </Box>

      {/* Search Input takes remaining space */}
      <Box sx={{ flexGrow: 1 }}>
        <DeviceSearchInput onSelect={handleSelectBattery} />
      </Box>

      {haveSearchValues && (
        <IconButton sx = {{
          "&:hover": {
            backgroundColor: "#E6E6E6",
            color: "#970625",
          },

        }} onClick={handleClearSelection} size = "small">
          <CloseIcon sx={{fontSize: "14px"}}/>
        </IconButton>
      )}

      {haveSearchValues && (<Box sx = {{ marginLeft: '6px', marginRight: '8px', height: '18px', borderRight: '1px solid #CBCBCB'}}></Box>)}

      <IconButton
        onClick={handleSearchButton}
        size="small"
        sx={{
          pointerEvents: haveSearchValues ? "auto" : "none", 
          cursor: haveSearchValues ? "pointer" : "default",
          backgroundColor: haveSearchValues ? "#6D72F6" : "#CCCCCC",
          borderRadius: "50%",
          "&:hover": { backgroundColor: haveSearchValues ? "#6D72F6" : "#CCCCCC" }
        }}
      >
        <ArrowForwardIcon
          sx={{
            fontSize: "12px",
            color: haveSearchValues ? "#fff" : "#9F9F9F",
          }}
        />
      </IconButton>
    </Box>
  );
}
