import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Typography, Checkbox, FormControlLabel, styled, Button, TextField, InputAdornment } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CustomCalendar from '../../../../Components/CustomCalendar';

import SearchIcon from "@mui/icons-material/Search";
import { convertZonetoUTCEnd, convertZonetoUTCStart, getDatePickerDate } from '../../../../Helper/DatePicker/DateConverter';
import { CheckBoxFormControlLabel, PrimaryTextButton } from '../TableStyles';

const StyledFilterTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme, isFilterApplied }) => ({
      textTransform: "none",
      minWidth: 0,
      minHeight: "10px",
      width: "100%",
      textAlign: "left",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 500,
      padding: "4px 16px 4px 8px",
      borderRadius: "8px",
      transition: "background 0.2s ease-in-out, color 0.2s ease-in-out",
      alignItems: "start",
      marginBottom: "2px",
      color: "#3D4B5A",
      position: "relative",
  
      "&:hover": {
        background: "#F7F7F7",
        color: "#3D4B5A",
      },
      "&.Mui-selected": {
        background: "#EEEFFF",
        color: "#6D72F6",
        fontWeight: "bold",
      },
  
      // Custom left highlighter
      ...(isFilterApplied && {
        "&::before": {
          content: '""',
          position: "absolute",
          left: 0,
          top: "25%", 
          height: "50%",
          width: "2px",
          backgroundColor: "#6D72F6",
          borderRadius: "2px",
        },
      }),
    })
);

const RenderMultiSelectFilters = ({ filter, newFilters, onChange }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const filterValues = filter.values;
  const searchThreshold = 5

  const filteredValues = filterValues.filter((filterValue) =>
    filterValue.value.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box>

      {filterValues.length > searchThreshold && (
        <Box sx={{
          width: "100%",
          mb: 1,
          py: 0.5,
          paddingRight: '0px',
          border: "1px solid #EBEFF3",
          borderRadius: "12px"
        }}>
            <TextField
              variant="outlined"
              size="small"
              placeholder={`Search ${filter.label}`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx = {{ marginRight: '4px', marginLeft: '8px' }}>
                    <SearchIcon sx={{ color: "#808080", fontSize: '18px', p:0 }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '100%',
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  paddingLeft: '0px'
                },
                "& fieldset": {
                  border: "none !important",
                },
                "&:hover fieldset": {
                  border: " none !important",
                },
                "&.Mui-focused fieldset": {
                  border: "none !important",
                },
        
                "& .MuiInputBase-input": {
                    p: 0,
                    fontSize: "12px !important",
                    border: "none !important",
                    fontWight: 400, 
                    caretColor: "#5D5D5D",
                    "&::placeholder": {
                      fontSize: "12px",   
                      color: "#ADB5BE",  
                      opacity: 1, 
                    },
                }
              }}
            />
        </Box>
      )}

      <Box sx = {{height: '160px', overflow: 'auto', width: '100%'}}>
        {filteredValues.map((filterValue, index) => (
          <CheckBoxFormControlLabel
            key={index}
            control={
              <Checkbox
                sx = {{ color: '#9F9F9F'}}
                checked={newFilters?.[filter?.key]?.includes(filterValue.id) || false}
                onChange={onChange(filter.key, filterValue.id)}
              />
            }
            label={filterValue.value}
          />
        ))}
      </Box>
    </Box>
  );
};

const FilterBody = ({ filters = {}, newFilters, setNewFilters, activeFilterKey = null }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [filterOptions, setFilterOptions] = useState([]);
  

  const handleDateChangeFilter = (filterKey, filterValue) => {
    if(!filterValue || !filterValue.startDate || !filterValue.endDate) {
      return false
    }
    filterValue.startDateUTC = convertZonetoUTCStart(filterValue.startDate)
    filterValue.endDateUTC = convertZonetoUTCEnd(filterValue.endDate)


    setNewFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters[filterKey] = [JSON.stringify(filterValue)];
      return updatedFilters;
    })
  }

  const handleCheckboxChange = (filterKey, filterValue) => (event) => {
    setNewFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (event.target.checked) {
        if (!updatedFilters[filterKey]) {
          updatedFilters[filterKey] = [];
        }
        updatedFilters[filterKey].push(filterValue);
      } else {

        if (updatedFilters[filterKey]) {
          updatedFilters[filterKey] = updatedFilters[filterKey].filter(
            (value) => value !== filterValue
          );

          if (updatedFilters[filterKey].length === 0) {
            delete updatedFilters[filterKey];
          }
        }
      }
      return updatedFilters;
    });
  };

  const clearTabFilterSelection = () => {
    let key = filterOptions[selectedTab]?.key
    const updatedNewFilters = {...newFilters}
    delete updatedNewFilters[key]
    setNewFilters(updatedNewFilters)
  }

  const shouldShowClearSelection = () => {
    let key = filterOptions[selectedTab]?.key
    return newFilters && newFilters[key]
  }

  const setTheTabToCurrentFilterKey = () => {
    if (activeFilterKey && filterOptions.length > 0) {
      const index = filterOptions.findIndex(filter => filter.key === activeFilterKey);
      if (index !== -1) {
        setSelectedTab(index);
      }
    }
  }

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      setFilterOptions(Object.values(filters));
    }
  }, [filters]);

  useEffect(() => {
    setTheTabToCurrentFilterKey()
  }, [activeFilterKey, filterOptions]);
  

  const renderFilterSelectionView = (filter) => {
    if(!filter) {
        return null
    }

    if (filter?.displayType == 'dateCalender') {
        let currentFilter = newFilters[filter.key]
        let dates = [getDatePickerDate(undefined, -14), getDatePickerDate()]

        if (currentFilter && currentFilter[0]) {
          let {startDate, endDate} = JSON.parse(currentFilter[0])
          dates = [startDate, endDate]
        }

        return (
            <div>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '24px',
                    letterSpacing: '0%',
                    color: "#5E758D",
                    mb: 0.5
                  }}
                >Select onboarding date range</Typography>
            <CustomCalendar
              changeTheDate={(startDate, endDate) => { handleDateChangeFilter(filter.key, {startDate, endDate})}}
              date={dates}
              allowClear={true}
            />
          </div>
        )
    }

    return (
        <RenderMultiSelectFilters  
                filter = {filter} 
                newFilters = {newFilters} 
                onChange = {handleCheckboxChange}
        />
    )
  }
  
  return (
    <Box sx={{ display: 'flex' }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        visibleScrollbar={true}
        scrollButtons = {false}
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          width: '200px',
          height: '216px',
          padding: '8px 8px 8px 12px'
         
        }}
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
      >
        {filterOptions.map((filter, index) => (
          <StyledFilterTab
            key={index}
            isFilterApplied = {newFilters[filter.key]}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant=  {selectedTab === index ? "fw500s12lh24c6D72F6" : "fw400s12lh24c3D4B5A"}> {filter.label} </Typography>
                {selectedTab === index && (
                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#6D72F6',
                      marginLeft: 'auto',
                    }}
                  />
                )}
              </Box>
            }
          />
        ))}
      </Tabs>

      <Box sx={{ flexGrow: 1, padding: '8px 16px 8px 12px', height: '216px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box>
            {renderFilterSelectionView(filterOptions[selectedTab])}
        </Box>

        {shouldShowClearSelection() && <Box sx = {{textAlign: 'right'}}>
            <PrimaryTextButton onClick={() => clearTabFilterSelection()} sx = {{position: 'absolute', bottom: 60, right: 16}}> 
              Clear Selection 
            </PrimaryTextButton>
        </Box>
      }
      </Box>
    </Box>
  );
};

export default FilterBody;
