import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const ProgressBarColumnView = ({ 
    record, 
    config, 
    value, 
    barColor = 'green', 
    barStyle = {}, 
    textStyle = {}, 
    defaultText = "",
    minProgress = 0
}) => {
  // const progress = record?.[config?.dataIndex] ?? 0; // Default to 0 if no value
  let progress = value

  if (progress > 100) {
    progress = 100
  }

  progress = value >= minProgress 
  ? ((value - minProgress) / (100 - minProgress)) * 100 
  : 0;
  
  

  const shouldShowProgress = () => {
    return value ? true : false
  }

  progress = progress ? progress.toFixed(2) : progress

  if (!shouldShowProgress()) {
    return  <Typography variant="fw400s14lh24c3D4B5A">{defaultText}</Typography>
  }

  return (
    <Box display="flex" alignItems="center" gap={1} width="100%">
        <Box flexGrow={1}>
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            sx={{
              height: 8,
              borderRadius: 5,
              scaleV: progress,
              backgroundColor: progress >= 100 ? barColor : "#e0e0e0",
              '& .MuiLinearProgress-bar': {
                backgroundColor: barColor,
              },
              ...barStyle
            }}
          />
        </Box>
        
        <Typography variant="body2" 
          sx={{ 
              minWidth: 30, 
              fontSize: '14px',
              color: '#3D4B5A',
              ...textStyle 
          }}>
          {value}%
        </Typography>
    </Box>
  );
};

export default ProgressBarColumnView;
