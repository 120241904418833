import { LoadingButton } from "@mui/lab";
import { Badge, Box, Button, Chip, FormControlLabel, IconButton, styled, Tooltip, tooltipClasses } from "@mui/material";

export const ActiveButtonBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#768CFF",
    color: "#768CFF",
    width: "9px",
    height: "9px", 
    minWidth: "9px",
    borderRadius: "50%",
    top: 0
  },
}));
  
export const PrimaryIconButton = styled(IconButton)(({ active }) => ({
    width: 30,
    height: 30,
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #F3F3F3",
    color: "#5E758D",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
    "&:hover": {
      backgroundColor: '#F8F7FF',
      border: "1px solid #768CFF",
      "& .MuiSvgIcon-root": {
        color: "#8489FF",
      },
    },
    ...(active && {
      border: "1px solid #949494",
      "& .MuiSvgIcon-root": {
        color: "#2E2E2E",
        fontSize: "20px",
      },
    }),
}));


export const FilterBoxContainer = styled(Box)(({ hasActive }) => ({
    maxWidth: "250px",
    borderRadius: "14px",
    border: `1px solid ${hasActive ? "#6D72F6" : "#D7D7D7"}`,
    backgroundColor: "#FFFFFF",
    padding: "2px 10px",
    display: "flex",
    alignItems: "center",
    marginLeft: "6px",
    cursor: "pointer",
    transition: "border-color 0.2s ease-in-out",
    
    // Apply styles to CloseIcon inside FilterContainer
    "& .MuiSvgIcon-root": {
      fontSize: "14px",
      color: hasActive ? "#6D72F6" : "#535353",
      marginLeft: "8px",
      cursor: "pointer",
      padding: "2px",
      borderRadius: "50%",
      backgroundColor: "#FFFFFF",
      transition: "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: "#E6E6E6",
        color: "#970625",
      },
    },
}));


export const CheckBoxFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'block',
  marginLeft: '0px',
  '& .MuiTypography-root': {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0%',
    color: '#3D4B5A',
  },

  '& .MuiCheckbox-root': {
      paddingLeft: '0px',
      paddingRight: '8px',
      paddingTop: '8px',
      paddingBottom: '8px',
  },

  '& .MuiCheckbox-root.Mui-checked': {
    color: '#6D72F6 !important',
  },

  '& .MuiCheckbox-root.Mui-disabled': {
    color: '#989898 !important',
  },

}));


export const FilterBoxTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "250px",
    backgroundColor: '#363636CC',
    color: '#FFFFFF',
    fontSize: '10px',
    fontWeight: 400,
    marginTop: '2px !important',
    borderRadius: '6px',
    padding: '6px 8px'
  },
});



export const PrimaryTableChip =  styled(Chip)(({theme}) => ({
    background: "#F4F5FF",
    border: "1px solid #F4F5FF",
    borderRadius: "18px",
    maxHeight: '26px',
    fontSize: "12px",
    color: "#6D72F6",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "& .MuiChip-label": {
      p: 1
    },
    "& .MuiChip-deleteIcon": {
      color: "#6D72F6", 
      fontSize: '10px',
      borderRadius: "50%",
      marginRight: '5px',
      padding: '3px',
      "&:hover": {
        backgroundColor: "#E6E6E6",
        color: "#970625",
      },
    },
}))


// Buttons
export const BatchActionIconButton = styled(IconButton)(({ theme, active }) => ({
  width: 30,
  height: 30,
  backgroundColor: active ? "#EEEFFF" : "#F3F3F3",
  borderRadius: "50px",
  pointerEvents: active ? "auto" : "none", 
  cursor: active ? "pointer" : "default",
  "& .MuiSvgIcon-root": {
      color: active ? "#6D72F6" : "#797979",
      fontSize: "20px",
  },
  "&:hover": {
      backgroundColor: active ? "#D5D7FF" : "#F3F3F3",
      "& .MuiSvgIcon-root": {
      color: active ? "#4D44D8" : "#797979",
     },
  },
}));


export const PrimaryActionButtonForTable = styled(Button)({
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "20px",
  borderRadius: "10px",
  border: "1px solid #E4E4E4",
  backgroundColor: "#FFFFFF",
  padding: "4px 12px 4px 8px",
  textTransform: "none",
  color: "#000",

  "&:hover": {
      border: '1px solid #6D72F6',
      backgroundColor: "#FAF9FF",
      color: "#4D44D8"
  }
});


const BaseFormActionButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "20px",
  fontFamily: "Roboto",
  textTransform: "none",
  textAlign: "center",
  borderRadius: "10px",
  padding: "8px 24px",
}));


export const PrimaryFormActionButton = styled(BaseFormActionButton)({
  background: "#6D72F6",
  color: "#FFFFFF",
  "&:hover": { background: "#4D44D8"},
  "&:disabled": {
    background: "#CCCCCC",
    color: "#9F9F9F",
  },
});


export const CancelFormActionButton = styled(BaseFormActionButton)({
  border: "1px solid #EBEFF3",
  background: "#FFFFFF",
  color: "#3D4B5A",
  marginRight: "8px",
  "&:hover": {
     background: "#FFFFFF",
     border: '1px solid #A4A4A4',
     color: '#090909'
  },
});

export const PrimaryFormLoadingActionButton = styled(LoadingButton)(({ theme }) => ({
    fontWeight: '600 !important',
    fontSize: "14px !important",
    lineHeight: "20px !important",
    fontFamily: "Roboto",
    textTransform: "none",
    textAlign: "center",
    borderRadius: "10px",
    padding: "8px 24px",
    background: "#6D72F6",
    color: "#FFFFFF",

    "&:hover": {
        background: "#4D44D8",
    },
    "&:disabled": {
        background: "#CCCCCC",
        color: "#9F9F9F",
    },
}));


export const PrimaryTextButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    textAlign: 'right',
    textTransform: 'none', 
    color: '#6D72F6',
    padding: 0,
    minWidth: 'auto',
}))