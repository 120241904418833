import React from 'react';
import Grid2 from '@mui/material/Grid2';
import TableSearch from './TableSearch';
import EmailComposerDialog from './EmailComposerDialog';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Typography } from '@mui/material';
import { BatchActionIconButton, PrimaryActionButtonForTable } from './TableStyles';
import RecordCountBox from '../../../Components/RecordCountBox'


const TableHeaderAndActions = ({
    title,
    count,
    onPrimaryButtonClick,
    showPrimaryButton = true,
    primaryButtonLabel,
    onSearchApply,
    disableSearch,
    onSendEmail,
    selectedRows = [],
    getCSVData = () => {},
    onDownloadCSV = () => {},
    availableEmailDomains = []
}) => {

  let isBatchActionButtonActive = selectedRows && selectedRows.length > 0

  return (
    <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>

      <Grid2 container direction="row" alignItems="center" spacing={2} sx = {{minHeight: '35px'}}>
        <Typography variant="fw500s16lh24c000000"> {title}</Typography>
        <RecordCountBox count = {count} />

        {
          showPrimaryButton && <PrimaryActionButtonForTable  variant="outlined"  color="primary" onClick={onPrimaryButtonClick} >
          {primaryButtonLabel}
          </PrimaryActionButtonForTable>
        } 
      </Grid2>

      <Grid2 container direction="row" alignItems="center" spacing={1}>
      {
        !disableSearch && <TableSearch 
            onSearch={(selectedBatteries) => onSearchApply(selectedBatteries)} 
        />
      }

      { 
        selectedRows.length > 0 && <Typography variant="fw600s14lh16c6D72F6">  {selectedRows.length} selected </Typography>
      }

        <BatchActionIconButton
            onClick={onDownloadCSV}
            active={isBatchActionButtonActive}
        >
            <FileDownloadOutlinedIcon />
        </BatchActionIconButton>

        <EmailComposerDialog 
            availableEmailDomains = {availableEmailDomains} 
            getCSVData = {getCSVData} 
            selectedRows = {selectedRows} 
            active = {selectedRows.length > 0} 
            onSendEmail = {onSendEmail}
        />

        <BatchActionIconButton onClick={() => {}}  active = {false} >
            <MoreVertOutlinedIcon />
        </BatchActionIconButton>

      </Grid2>

    </Grid2>
  );
};

export default TableHeaderAndActions;